import React, { useRef } from 'react';
import styles from '../../styles/components/Columns.module.scss';
import classnames from 'classnames';
import useIntersectionObserver from '../../hooks/useIntersectionObserver';
import CountUp from 'react-countup';

export default function Columns({ data }) {
  const wrapperRef = useRef(null);
  const columnsRef = useRef(null);
  const onScreen = useIntersectionObserver(wrapperRef);
  const columnsOnScreen = useIntersectionObserver(columnsRef);

  return (
    <div
      className={classnames(
        styles.columnsWrapper,
        styles[data.style],
        onScreen?.isIntersecting ? styles.visible : ''
      )}
      id={data.anchorId}
      ref={wrapperRef}
    >
      <div className={styles.columsContentWrapper}>
        <h2>{data.title}</h2>
        <p>{data.subtitle}</p>
        <div className={styles.columnWrapper}>
          {data.items.map((item, index) => {
            return (
              <div
                className={classnames(
                  styles.column,
                  columnsOnScreen?.isIntersecting ? styles.visibleColumn : ''
                )}
                key={index}
                ref={columnsRef}
              >
                <img src={item.icon} alt="column icon" />
                {onScreen?.isIntersecting && (
                  <p>
                    <CountUp end={item.value} duration={1.2} separator={'.'} />
                  </p>
                )}
                <p>{item.title}</p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
