import React, { useContext } from 'react';
import styles from '../../styles/components/VideoStage.module.scss';
import classnames from 'classnames';
import { MobileContext } from '../../context/mobileContext';

export default function VideoStage({ data }) {
  const isMobile = useContext(MobileContext);
  return (
    <div
      className={classnames(styles.videoStageWrapper, styles[data.style])}
      id={data.anchorId}
    >
      <div className={styles.imageWrapper}>
        <img
          src={isMobile ? data.overlayImageM : data.overlayImage}
          alt="overlay"
        />
      </div>
      <div className={styles.contentWrapper}>
        <h1>{data.title}</h1>
        <p>{data.subtitle}</p>
      </div>
      <div
        className={styles.videoWrapper}
        style={{ background: data.overlayColor, opacity: data.overlayOpacity }}
      >
        <video
          poster={data.thumbnail}
          src={data.source}
          autoPlay
          muted
          loop
          playsInline
        ></video>
      </div>
    </div>
  );
}
