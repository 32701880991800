import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import LandingPage from './LandingPage';
import SecondLandingPage from './SecondLandingPage';
import SecondLandingPageApi from './SecondLandingPageApi';
import ThirdLandingPage from './ThirdLandingPage';

const router = createBrowserRouter([
  {
    path: 'second-page',
    element: <SecondLandingPage />,
  },
  {
    path: 'second-page-api',
    element: <SecondLandingPageApi />,
  },
  {
    path: 'third-page',
    element: <ThirdLandingPage />,
  },
  {
    path: '*',
    element: <SecondLandingPageApi />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<RouterProvider router={router} />);
