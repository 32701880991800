import React, { useContext, useRef } from 'react';
import styles from '../../styles/components/ModuleTitle.module.scss';
import classnames from 'classnames';
import { MobileContext } from '../../context/mobileContext';
import useIntersectionObserver from '../../hooks/useIntersectionObserver';

export default function ModuleTitle({ data }) {
  const isMobile = useContext(MobileContext);
  const wrapperRef = useRef(null);
  const onScreen = useIntersectionObserver(wrapperRef);
  return (
    <div
      className={classnames(
        styles.moduleTitleWrapper,
        styles[data.style],
        onScreen?.isIntersecting ? styles.visible : ''
      )}
      id={data.anchorId}
      ref={wrapperRef}
      style={{ background: data.backgroundColor }}
    >
      <div className={styles.contentWrapper}>
        <div className={styles.titleWrapper}>
          <div className={styles.imgWrapper}>
            <img
              src={isMobile ? data.decorationMob : data.decoration}
              alt="module title background"
            />
          </div>
          <h2>{data.title}</h2>
        </div>
        {data.subtitle && <p>{data.subtitle}</p>}
      </div>
    </div>
  );
}
