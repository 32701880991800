import React from 'react';
import styles from '../../styles/components/Footer.module.scss';
import classnames from 'classnames';

export default function Footer({ data }) {
  return (
    <div className={classnames(styles.footerWrapper, styles[data?.style])}>
      <ul className={styles.menu}>
        {data?.links.map((item, index) => {
          let menuItem;
          if (item.external) {
            menuItem = (
              <a
                href={item.url}
                target={item.newTab ? '_blank' : '_self'}
                rel="noreferrer"
              >
                {item.text}
              </a>
            );
          } else {
            menuItem = <a href={`#${item.anchorId}`}>{item.text}</a>;
          }
          return <li key={index}>{menuItem}</li>;
        })}
      </ul>
    </div>
  );
}
