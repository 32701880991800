export const data = {
  style: 'default',
  logo: '/images/logo.svg',
  links: [
    {
      external: false,
      anchorId: 'unser-angebot',
      text: 'Unser Angebot',
    },
    {
      external: false,
      anchorId: 'unsere-raume',
      text: 'Unsere Räume',
    },
    {
      external: false,
      anchorId: 'image-slider',
      text: 'Unsere Standorte',
    },
    {
      external: false,
      anchorId: 'faq-accordion',
      text: 'FAQs',
    },
  ],
  contact: {
    text: 'Räume buchen',
    icon: '/images/contact-icon.svg',
    anchorId: 'contact',
  },
};
