export const data = {
  style: 'default',
  logo: '/images/logo.svg',
  links: [
    {
      external: false,
      anchorId: 'unsere-erfahrung',
      text: 'Unsere Erfahrung',
    },
    {
      external: false,
      anchorId: 'unsere-methoden',
      text: 'Unsere Methoden',
    },
    {
      external: true,
      url: 'https://www.google.com',
      text: 'Unsere Standorte',
      newTab: false,
    },
    {
      external: true,
      url: 'https://www.google.com',
      text: 'Unsere Trainings',
      newTab: true,
    },
  ],
  contact: {
    text: 'Kontaktiere uns!',
    icon: '/images/contact-icon.svg',
    anchorId: 'contact',
  },
};
