import * as buildings from './animation.json';
import * as website from './website.json';

const animations = [
  {
    id: 0,
    title: buildings,
  },
  {
    id: 1,
    title: website,
  },
];

export default animations;
