import VideoStage from '../components/video-stage/video-stage';
import Columns from '../components/columns/columns';
import ModuleTitle from '../components/module-title/module-title';
import TextImageSlider from '../components/text-image-slider/text-image-slider';
import TextImage from '../components/text-image/text-image';
import ContactForm from '../components/contact-form/contact-form';
import TabNavigation from '../components/tab-navigation/tab-navigation';
import InteractiveMap from '../components/interactive-map/interactive-map';
import Faq from '../components/faq/faq';
import Gallery from '../components/gallery/gallery';

export default function RenderService(component) {
  switch (component.type) {
    case 'video-stage':
      return (
        <VideoStage
          key={`component-${component.id}`}
          data={component}
        ></VideoStage>
      );
    case 'columns':
      return (
        <Columns key={`component-${component.id}`} data={component}></Columns>
      );
    case 'module-title':
      return (
        <ModuleTitle
          key={`component-${component.id}`}
          data={component}
        ></ModuleTitle>
      );
    case 'text-image-slider':
      return (
        <TextImageSlider
          key={`component-${component.id}`}
          data={component}
        ></TextImageSlider>
      );
    case 'text-image':
      return (
        <TextImage
          key={`component-${component.id}`}
          data={component}
        ></TextImage>
      );
    case 'contact-form':
      return (
        <ContactForm
          key={`component-${component.id}`}
          data={component}
        ></ContactForm>
      );
    case 'tab-navigation':
      return (
        <TabNavigation
          key={`component-${component.id}`}
          data={component}
        ></TabNavigation>
      );
    case 'interactive-map':
      return (
        <InteractiveMap
          key={`component-${component.id}`}
          data={component}
        ></InteractiveMap>
      );
    case 'faq':
      return <Faq key={`component-${component.id}`} data={component}></Faq>;
    case 'gallery':
      return (
        <Gallery key={`component-${component.id}`} data={component}></Gallery>
      );
    default:
      return <div key={`component-${component.id}`}>{component.type}</div>;
  }
}
