import React, { useEffect, useState } from 'react';
import { MobileContext } from './context/mobileContext';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import './styles/globals.scss';
import Layout from './components/layout/layout';
import { data as headerData } from './mock-data/header-second';
import { data as footerData } from './mock-data/footer-second';
import { data as pageData } from './mock-data/third-page';
import RenderService from './services/RenderService';

export default function ThirdLandingPage() {
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 960);
    };
    if (typeof window !== 'undefined') {
      setIsMobile(window.innerWidth < 960);
      window.addEventListener('resize', handleResize);
    }
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <HelmetProvider>
      <MobileContext.Provider value={isMobile}>
        <Helmet>
          <title>{pageData.title}</title>
          <meta name="title" content={pageData.metaTitle} />
          <meta name="description" content={pageData.metaDescription} />
          <meta property="og:title" content={pageData.metaTitle} />
          <meta property="og:description" content={pageData.metaDescription} />
          <meta property="og:image" content={pageData.metaImage} />
          <meta name="twitter:title" content={pageData.metaTitle} />
          <meta name="twitter:description" content={pageData.metaDescription} />
          <meta name="twitter:image" content={pageData.metaImage} />
          <meta name="twitter:card" content="summary_large_image" />
          <link rel="icon" href="/favicon.ico" />
        </Helmet>
        <Layout headerData={headerData} footerData={footerData}>
          <div id="page-top"></div>
          {pageData.components.map((item) => {
            return RenderService(item);
          })}
        </Layout>
      </MobileContext.Provider>
    </HelmetProvider>
  );
}
