import React, {
  useRef,
  useLayoutEffect,
  useState,
  useEffect,
  useContext,
} from 'react';
import styles from '../../styles/components/InteractiveMap.module.scss';
import classnames from 'classnames';
import useIntersectionObserver from '../../hooks/useIntersectionObserver';
import H from '@here/maps-api-for-javascript';
import '@here/maps-api-for-javascript/bin/mapsjs-ui.css';
import { MobileContext } from '../../context/mobileContext';

import markerIcon from '../../assets/images/marker.png';
import mapIcon from '../../assets/images/map.svg';
import locationIcon from '../../assets/images/locations.svg';
import filtersIcon from '../../assets/images/filters.svg';

export default function InteractiveMap({ data }) {
  const wrapperRef = useRef(null);
  const mapRef = useRef(null);
  const onScreen = useIntersectionObserver(wrapperRef);
  const [filters, setFilters] = useState([]);
  const [filteredLocations, setFilteredLocations] = useState([]);
  const [mapState, setMapState] = useState(null);
  const [iconState, setIconState] = useState(null);
  const [uiState, setUiState] = useState(null);
  const [filterExpand, setFilterExpand] = useState(false);
  const [activeFilters, setActiveFilters] = useState(false);
  const [mobileMap, setMobileMap] = useState('locations');
  const [active, setActive] = useState([]);
  const isMobile = useContext(MobileContext);

  const addMarkersToMap = (data, map, ui) => {
    const marker = new H.map.Marker(
      {
        lat: data.lat,
        lng: data.lng,
      },
      { icon: data.icon }
    );

    const bubble = new H.ui.InfoBubble({lat: data.lat, lng: data.lng});
    bubble.setContent(
      `<h5>${data.text.name}</h5>
      <p>${data.text.address}</p>
      <div class=information>
      ${
        data.text.route
          ? `<p><a href="${data.text.route}" target="_blank"><span>Anfahrtsbeschreibung</span></a></p>`
          : ''
      }
      ${
        data.text.phone
          ? `<p><a href="tel:${data.text.phone}"><span>${data.text.phone}</span></a></p>`
          : ''
      }
      ${
        data.text.email
          ? `<p><a href="mailto:${data.text.email}"><span>${data.text.email}</span></a></p>`
          : ''
      }
      ${
        data.text.url
          ? data.text.external
            ? `<p><a href="${data.text.url}" target=${
                data.text.newTab ? '_blank' : '_self'
              } rel="noreferrer" ><span>${data.text.urlText}</span></a></p>`
            : `<p><a href="${`#${data.text.url}`}"><span>${
                data.text.urlText
              }</span></a></p>`
          : ''
      }
      </div>
      <p>${data.text.text}</p>`
    );
    bubble.setData(bubble);
    bubble.addClass(styles.info);

    marker.setData(bubble);
    map.addObject(marker);
    ui.addBubble(bubble);

    bubble.close();

    marker.addEventListener(
      'tap',
      function (evt) {
        const zoom = map.getViewModel().getLookAtData().zoom;
        map.getViewModel().setLookAtData(
          {
            position: marker.getGeometry(),
            zoom: zoom > 9 ? zoom : 9,
          },
          true
        );
        const bubbles = ui.getBubbles();
        bubbles.map((item) => {
          item.close();
          return null;
        });
        evt.target.getData().open();
      },
      false
    );
  };

  const addFilter = (filter) => {
    const objects = mapState.getObjects();
    mapState.removeObjects(objects);
    const bubbles = uiState.getBubbles();

    bubbles.map((item) => {
      item.close();
      uiState.removeBubble(item);
      return null;
    });

    const tempFilters = [...active];
    if (!tempFilters.includes(filter)) {
      tempFilters.push(filter);
    } else {
      const index = tempFilters.indexOf(filter);
      tempFilters.splice(index, 1);
    }
    const tempLocations = [];
    data.locations.map((location) => {
      tempFilters.map((tempFilter) => {
        if (location.categories.includes(tempFilter)) {
          tempLocations.push(location);
        }
        return false;
      });
      return false;
    });

    const tempUniqueLocations = tempLocations.filter(
      (value, index, self) =>
        index === self.findIndex((t) => t.name === value.name)
    );

    if (tempUniqueLocations.length === 0) {
      setFilteredLocations(data.locations);
      setActiveFilters(false);
      setActive([]);
    } else {
      setFilteredLocations(tempUniqueLocations);
      setActiveFilters(true);
      setActive(tempFilters);
    }
  };

  const addFilterGroup = (filter) => {
    const objects = mapState.getObjects();
    mapState.removeObjects(objects);
    const bubbles = uiState.getBubbles();

    bubbles.map((item) => {
      item.close();
      uiState.removeBubble(item);
      return null;
    });

    const tempFilters = [...active];
    if (!tempFilters.includes(filter)) {
      tempFilters.push(filter);
    } else {
      const index = tempFilters.indexOf(filter);
      tempFilters.splice(index, 1);
    }
    const tempGroupFilters = [];

    data.filterGroups.map((group) => {
      tempFilters.map((filter) => {
        if (group.filters.includes(filter)) {
          tempGroupFilters.push({
            name: group.name,
            type: group.type,
            filter: filter,
          });
        }
        return false;
      });
      return false;
    });

    const groupByCategory = tempGroupFilters.reduce((group, filter) => {
      let { name } = filter;
      name = name.toLowerCase().split(' ').join('-');
      group[name] = group[name] ?? {};

      group[name]['type'] = filter.type;
      if (group[name]['filters']) {
        group[name]['filters'].push(filter.filter);
      } else {
        group[name]['filters'] = [filter.filter];
      }

      return group;
    }, {});

    const filterList = [];
    for (const [, value] of Object.entries(groupByCategory)) {
      filterList.push(value);
    }

    const tempLocations = [];

    data.locations.map((location) => {
      const locationFilters = location.categories;
      const validArr = [];
      filterList.map((list) => {
        let valid;
        if (list.type === 'OR') {
          valid = false;
          list.filters.map((filter) => {
            if (locationFilters.includes(filter)) valid = true;
            return false;
          });
        } else if (list.type === 'AND') {
          valid = true;
          list.filters.map((filter) => {
            if (!locationFilters.includes(filter)) valid = false;
            return false;
          });
        }
        validArr.push(valid);
        return false;
      });
      let valid = true;
      validArr.map((item) => {
        valid = valid && item;
        return false;
      });
      if (valid === true) {
        tempLocations.push(location);
      }
      return false;
    });

    setFilteredLocations(tempLocations);
    setActiveFilters(true);
    setActive(tempFilters);

    if (tempFilters.length === 0) {
      setFilteredLocations(data.locations);
      setActiveFilters(false);
      setActive([]);
    } else {
      setFilteredLocations(tempLocations);
      setActiveFilters(true);
      setActive(tempFilters);
    }
  };

  useLayoutEffect(() => {
    if (!mapRef.current) return;
    const domainConfig = {};

    // new url:
    // https://apis.hub.db.de/db/apis/maptile-vector/v1/vectortiles
    // https://apis.deutschebahn.com/db/apis/maptile-vector/v1/vectortiles
    domainConfig[H.service.omv.Service.CONFIG_KEY] = {
      baseUrl: new H.service.Url(
        'https',
        'apis.deutschebahn.com',
        'db/apis/maptile-vector/v1/vectortiles/base/mc'
      ),
      // 'https',
      //  'gateway.businesshub.deutschebahn.com',
      //  'here-maps/maptile-vector/vectortiles/base/mc'
      // ),
      headers: {
        "DB-Client-ID": process.env.REACT_APP_MAP_DB_CLIENT_ID,
        "DB-Api-Key": process.env.REACT_APP_MAP_DB_API_KEY,
        key: process.env.REACT_APP_MAP_API_KEY
      },
      subdomain: null,
    };

    const platform = new H.service.Platform({
      // apikey: process.env.REACT_APP_MAP_API_KEY,
      apikey: process.env.REACT_APP_MAP_DB_API_KEY,
      servicesConfig: domainConfig,
    });

    const defaultLayers = platform.createDefaultLayers({ lg: 'de' });
    const hMap = new H.Map(mapRef.current, defaultLayers.vector.normal.map, {
      center: { lat: 51.1657, lng: 10.4515 },
      zoom: 6.5,
      pixelRatio: window.devicePixelRatio || 1,
    });
    hMap.getBaseLayer().setMin(5);

    // eslint-disable-next-line
    const behavior = new H.mapevents.Behavior(new H.mapevents.MapEvents(hMap));
    // behavior.disable(H.mapevents.Behavior.DRAGGING);
    // behavior.disable(H.mapevents.Behavior.WHEELZOOM);
    // behavior.disable(H.mapevents.Behavior.Feature.PANNING);
    // behavior.disable(H.mapevents.Behavior.Feature.PINCH_ZOOM);
    // behavior.disable(H.mapevents.Behavior.Feature.WHEEL_ZOOM);
    // behavior.disable(H.mapevents.Behavior.Feature.DBL_TAP_ZOOM);
    // behavior.disable(H.mapevents.Behavior.Feature.FRACTIONAL_ZOOM);
    // behavior.disable(H.mapevents.Behavior.Feature.HEADING);
    // behavior.disable(H.mapevents.Behavior.Feature.TILT);

    const ui = H.ui.UI.createDefault(hMap, defaultLayers);
    const icon = new H.map.Icon(markerIcon);

    setMapState(hMap);
    setUiState(ui);
    setIconState(icon);

    return () => {
      hMap.dispose();
    };
  }, [mapRef]);

  useEffect(() => {
    const tempFilters = [];
    if (data.style === 'default' || data.style === 'secondDefault') {
      data.locations.map((location) => {
        if (location.categories) {
          location.categories.map((category) => {
            tempFilters.push(category);
            return false;
          });
        }
        return false;
      });
      const onlyUnique = (value, index, self) => {
        return self.indexOf(value) === index;
      };
      const tempUniqueFilters = tempFilters.filter(onlyUnique);
      setFilters(tempUniqueFilters);
      setFilteredLocations(data.locations);
    } else if (data.style === 'group-filters') {
      data.filterGroups.map((group) => {
        if (group.filters) {
          group.filters.map((filter) => {
            tempFilters.push(filter);
            return false;
          });
        }
        return false;
      });
      const onlyUnique = (value, index, self) => {
        return self.indexOf(value) === index;
      };
      const tempUniqueFilters = tempFilters.filter(onlyUnique);
      setFilters(tempUniqueFilters);
      setFilteredLocations(data.locations);
    }
  }, [data]);

  useEffect(() => {
    if (mobileMap === 'map') {
      mapState.getViewPort().resize();
    }
  }, [mobileMap, mapState]);

  useEffect(() => {
    if (filteredLocations.length > 0 && uiState && mapState && iconState) {
      filteredLocations.map((location) => {
        addMarkersToMap(
          {
            lat: location.lat,
            lng: location.lng,
            text: location,
            icon: iconState,
          },
          mapState,
          uiState
        );
        return false;
      });
    }
  }, [filteredLocations, uiState, mapState, iconState]);

  return (
    <div
      className={classnames(
        styles.interactiveMapWrapper,
        styles[data.style],
        onScreen?.isIntersecting ? styles.visible : ''
      )}
      id={data.anchorId}
      ref={wrapperRef}
    >
      {!isMobile ? (
        <div className={styles.desktop}>
          <div className={styles.filters}>
            <h4>Alle Standorte</h4>
            <div
              className={styles.filtersButton}
              onClick={() => {
                setFilterExpand((prevFilterExpand) => !prevFilterExpand);
              }}
            >
              <img src={filtersIcon} alt="filters" />
              <p
                className={classnames(
                  styles.arrow,
                  filterExpand ? styles.arrowRotate : ''
                )}
              >
                {filterExpand ? 'Filter ausblenden' : 'Filter anzeigen'}
              </p>
            </div>
            {activeFilters && (
              <p
                onClick={() => {
                  setFilteredLocations(data.locations);
                  setActiveFilters(false);
                  setActive([]);
                }}
              >
                x Filter entfernen
              </p>
            )}
            <div className={styles.filteredLocations}>
              {filterExpand &&
                (data.style === 'default' ||
                  data.style === 'secondDefault') && (
                  <div className={styles.filterWrapper}>
                    {filters.map((filter, index) => {
                      return (
                        <div
                          key={index}
                          onClick={() => {
                            addFilter(filter);
                          }}
                          className={classnames(
                            styles.filter,
                            active.includes(filter) ? styles.activeFilter : null
                          )}
                        >
                          {filter}
                        </div>
                      );
                    })}
                  </div>
                )}
              {filterExpand && data.style === 'group-filters' && (
                <div className={styles.filterWrapper}>
                  {data.filterGroups.map((group, index) => {
                    return (
                      <div className={styles.filtersCategory} key={index}>
                        <div className={styles.filterName}>{group.name}</div>
                        {group.filters.map((filter, index) => {
                          return (
                            <div
                              key={index}
                              onClick={() => {
                                addFilterGroup(filter);
                              }}
                              className={classnames(
                                styles.filter,
                                active.includes(filter)
                                  ? styles.activeFilter
                                  : null
                              )}
                            >
                              {filter}
                            </div>
                          );
                        })}
                      </div>
                    );
                  })}
                </div>
              )}
              {filteredLocations.length > 0 &&
                filteredLocations.map((location, index) => {
                  return (
                    <div
                      key={index}
                      className={styles.location}
                      onClick={() => {
                        const objects = mapState.getObjects();
                        objects.map((item) => {
                          const geo = item.getGeometry();
                          if (
                            geo.lng === location.lng &&
                            geo.lat === location.lat
                          ) {
                            mapState.getViewModel().setLookAtData(
                              {
                                position: item.getGeometry(),
                                zoom: 9,
                              },
                              true
                            );
                            const bubbles = uiState.getBubbles();
                            bubbles.map((item) => {
                              item.close();
                              return null;
                            });
                            item.getData().open();
                          }
                          return null;
                        });
                      }}
                    >
                      <h5>{location.name}</h5>
                      <p>{location.address}</p>
                      {location.phone ? <p>{location.phone}</p> : null}
                      {location.url ? (
                        <p>
                          {location.external ? (
                            <a
                              href={location.url}
                              target={location.newTab ? '_blank' : '_self'}
                              rel="noreferrer"
                            >
                              {location.urlText}
                            </a>
                          ) : (
                            <a href={`#${location.url}`}>{location.urlText}</a>
                          )}
                        </p>
                      ) : null}
                    </div>
                  );
                })}
            </div>
          </div>
          <div
            className={styles.mapWrapper}
            ref={mapRef}
            style={{ width: '1000px', height: '900px' }}
          ></div>
        </div>
      ) : (
        <div className={styles.mobile}>
          <div className={styles.filters}>
            <h4>Alle Standorte</h4>
            <div className={styles.filtersButton}>
              <p
                className={classnames(
                  styles.arrow,
                  filterExpand ? styles.arrowRotate : ''
                )}
                onClick={() => {
                  if (filterExpand) {
                    setMobileMap('locations');
                  } else {
                    setMobileMap('filters');
                  }
                  setFilterExpand((prevFilterExpand) => !prevFilterExpand);
                }}
              >
                {filterExpand ? 'Filter ausblenden' : 'Filter anzeigen'}
              </p>
              <div
                className={classnames(
                  styles.buttonMap,
                  mobileMap === 'map' ? styles.active : null
                )}
                onClick={() => setMobileMap('map')}
              >
                <img src={mapIcon} alt="map-button" />
              </div>
              <div
                className={classnames(
                  styles.buttonMap,
                  mobileMap === 'locations' ? styles.active : null
                )}
                onClick={() => setMobileMap('locations')}
              >
                <img src={locationIcon} alt="locations-button" />
              </div>
            </div>
            {activeFilters && (
              <p
                onClick={() => {
                  setFilteredLocations(data.locations);
                  setActiveFilters(false);
                  setActive([]);
                }}
              >
                x Filter entfernen
              </p>
            )}
            {mobileMap !== 'map' ? (
              <div className={styles.filteredLocations}>
                {filterExpand &&
                (data.style === 'default' || data.style === 'secondDefault') &&
                mobileMap === 'filters' ? (
                  <div className={styles.filterWrapper}>
                    {filters.map((filter, index) => {
                      return (
                        <div
                          key={index}
                          onClick={() => {
                            addFilter(filter);
                          }}
                          className={classnames(
                            styles.filter,
                            active.includes(filter) ? styles.activeFilter : null
                          )}
                        >
                          {filter}
                        </div>
                      );
                    })}
                  </div>
                ) : null}
                {filterExpand &&
                data.style === 'group-filters' &&
                mobileMap === 'filters' ? (
                  <div className={styles.filterWrapper}>
                    {data.filterGroups.map((group, index) => {
                      return (
                        <div className={styles.filtersCategory} key={index}>
                          <div className={styles.filterName}>{group.name}</div>
                          {group.filters.map((filter, index) => {
                            return (
                              <div
                                key={index}
                                onClick={() => {
                                  addFilterGroup(filter);
                                }}
                                className={classnames(
                                  styles.filter,
                                  active.includes(filter)
                                    ? styles.activeFilter
                                    : null
                                )}
                              >
                                {filter}
                              </div>
                            );
                          })}
                        </div>
                      );
                    })}
                  </div>
                ) : null}
                {filteredLocations.length > 0 && mobileMap === 'locations'
                  ? filteredLocations.map((location, index) => {
                      return (
                        <div
                          key={index}
                          className={styles.location}
                          onClick={() => {
                            const objects = mapState.getObjects();
                            objects.map((item) => {
                              const geo = item.getGeometry();
                              if (
                                geo.lng === location.lng &&
                                geo.lat === location.lat
                              ) {
                                const bubbles = uiState.getBubbles();
                                bubbles.map((item) => {
                                  item.close();
                                  return null;
                                });
                                item.getData().open();
                              }
                              return null;
                            });
                          }}
                        >
                          <h5>{location.name}</h5>
                          <p>{location.address}</p>
                          {location.phone ? <p>{location.phone}</p> : null}
                          {location.url ? (
                            <p>
                              {location.external ? (
                                <a
                                  href={location.url}
                                  target={location.newTab ? '_blank' : '_self'}
                                  rel="noreferrer"
                                >
                                  {location.urlText}
                                </a>
                              ) : (
                                <a href={`#${location.url}`}>
                                  {location.urlText}
                                </a>
                              )}
                            </p>
                          ) : null}
                        </div>
                      );
                    })
                  : null}
              </div>
            ) : null}
          </div>
          <div
            className={classnames(
              styles.mapWrapper,
              mobileMap !== 'map' ? styles.invisibleMap : null
            )}
            ref={mapRef}
            style={{
              width: '100%',
              height: mobileMap === 'map' ? '80vh' : '900px',
            }}
          ></div>
        </div>
      )}
    </div>
  );
}
