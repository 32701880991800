import React, { useRef, useEffect, useState } from 'react';
import styles from '../../styles/components/Faq.module.scss';
import classnames from 'classnames';
import useIntersectionObserver from '../../hooks/useIntersectionObserver';

export default function Faq({ data }) {
  const wrapperRef = useRef(null);
  const onScreen = useIntersectionObserver(wrapperRef);
  const [activeTab, setActiveTab] = useState(null);
  const elemHeight = useRef([]);

  useEffect(() => {
    setActiveTab(0);
  }, [data]);

  return (
    <div
      className={classnames(
        styles.faqWrapper,
        styles[data.style],
        onScreen?.isIntersecting ? styles.visible : ''
      )}
      id={data.anchorId}
      ref={wrapperRef}
    >
      <div className={styles.contentWrapper}>
        {data?.data?.map((item, index) => {
          return (
            <div
              className={styles.questionWrapper}
              key={index}
              onClick={() => setActiveTab(index)}
            >
              <p
                className={classnames(
                  styles.question,
                  activeTab === index ? styles.rotateQuestion : null
                )}
              >
                {item.question}
              </p>
              <p
                className={classnames(
                  styles.answer,
                  activeTab === index ? styles.show : null
                )}
                ref={(node) => (elemHeight.current[index] = node)}
                style={
                  activeTab === index
                    ? {
                        maxHeight:
                          elemHeight.current[index].scrollHeight + 'px',
                      }
                    : { maxHeight: '0px' }
                }
              >
                {item.answer}
              </p>
            </div>
          );
        })}
      </div>
    </div>
  );
}
