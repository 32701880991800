import React, { useRef } from 'react';
import styles from '../../styles/components/TextImage.module.scss';
import classnames from 'classnames';
import useIntersectionObserver from '../../hooks/useIntersectionObserver';

export default function TextImage({ data }) {
  const wrapperRef = useRef(null);
  const onScreen = useIntersectionObserver(wrapperRef);
  return (
    <div
      className={classnames(
        styles.textImageWrapper,
        styles[data.style],
        data.imagePosition === 'right' ? styles.reverse : '',
        onScreen?.isIntersecting ? styles.visible : ''
      )}
      id={data.anchorId}
      ref={wrapperRef}
    >
      <div className={styles.image}>
        <img src={data.image} alt="slide" />
      </div>
      <div className={styles.content}>
        <h3>{data.title}</h3>
        <div
          className={styles.text}
          dangerouslySetInnerHTML={{
            __html: data.subtitle,
          }}
        ></div>
        {data.external ? (
          <a
            className={classnames(styles.button, styles[data.ctaStyle])}
            href={data.ctaURL}
            target={data.newTab ? '_blank' : '_self'}
            rel="noreferrer"
          >
            {data.ctaText}
          </a>
        ) : (
          <a
            className={classnames(styles.button, styles[data.ctaStyle])}
            href={`#${data.ctaURL}`}
          >
            {data.ctaText}
          </a>
        )}
      </div>
    </div>
  );
}
