import React, { useEffect, useState } from 'react';
import { MobileContext } from './context/mobileContext';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import './styles/globals.scss';
import Layout from './components/layout/layout';
import RenderService from './services/RenderService';
import { API_URL } from './api';

export default function SecondLandingPageApi() {
  const [isMobile, setIsMobile] = useState(false);
  const [components, setComponents] = useState([]);

  const useFetch = (url) => {
    const [data, setData] = useState(null);

    useEffect(() => {
      async function fetchData() {
        const response = await fetch(url);
        const json = await response.json();
        setData(json);
      }
      fetchData();
    }, [url]);

    return data;
  };

  const data = useFetch(API_URL);

  useEffect(() => {
    if (!data) return;
    const tempComponents = [...data.page.components].sort(function (a, b) {
      const keyA = a.id,
        keyB = b.id;
      if (keyA < keyB) return -1;
      if (keyA > keyB) return 1;
      return 0;
    });
    setComponents(tempComponents);
  }, [data]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 960);
    };
    if (typeof window !== 'undefined') {
      setIsMobile(window.innerWidth < 960);
      window.addEventListener('resize', handleResize);
    }
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const href = window.location.href;
    if (components && href.includes('#')) {
      const id = `${href.substring(href.indexOf('#') + 1)}`;
      const anchor = document.getElementById(id);
      if (anchor) {
        setTimeout(function () {
          window.scrollTo({
            top: anchor.offsetTop - 85,
            left: 0,
            behavior: 'smooth',
          });
        }, 300);
      }
    }
  }, [components]);

  return (
    <HelmetProvider>
      <MobileContext.Provider value={isMobile}>
        <Helmet>
          <title>{data?.page.title}</title>
          <meta name="title" content={data?.page.metaTitle} />
          <meta name="description" content={data?.page.metaDescription} />
          <meta property="og:title" content={data?.page.metaTitle} />
          <meta
            property="og:description"
            content={data?.page.metaDescription}
          />
          <meta property="og:image" content={data?.page.metaImage} />
          <meta name="twitter:title" content={data?.page.metaTitle} />
          <meta
            name="twitter:description"
            content={data?.page.metaDescription}
          />
          <meta name="twitter:image" content={data?.page.metaImage} />
          <meta name="twitter:card" content="summary_large_image" />
          <link rel="icon" href="/favicon.ico" />
        </Helmet>
        <Layout headerData={data?.header} footerData={data?.footer}>
          <div id="page-top"></div>
          {components?.map((item) => {
            return RenderService(item);
          })}
        </Layout>
      </MobileContext.Provider>
    </HelmetProvider>
  );
}
