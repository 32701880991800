import React, { useEffect, useState, useContext, useRef } from 'react';
import styles from '../../styles/components/ContactForm.module.scss';
import classnames from 'classnames';
import { useForm } from 'react-hook-form';
import { MobileContext } from '../../context/mobileContext';
import useIntersectionObserver from '../../hooks/useIntersectionObserver';
import Select from 'react-select';

export default function ContactForm({ data }) {
  const [name, setName] = useState(null);
  const [email, setEmail] = useState(null);
  const [msg, setMsg] = useState(null);
  const [valueName, setValueName] = useState('');
  const [valueEmail, setValueEmail] = useState('');
  const [valueMsg, setValueMsg] = useState('');

  const [vorname, setVorname] = useState(null);
  const [nachname, setNachname] = useState(null);
  const [firma, setFirma] = useState(null);
  const [anrede, setAnrede] = useState(null);
  const [titel, setTitel] = useState(null);
  const [standort, setStandort] = useState(null);
  const [valueVorname, setValueVorname] = useState('');
  const [valueNachname, setValueNachname] = useState('');
  const [valueFirma, setValueFirma] = useState('');
  const [valueAnrede, setValueAnrede] = useState(null);
  const [valueTitel, setValueTitel] = useState('');
  const [valueStandort, setValueStandort] = useState(null);

  const [formSubmitted, setFormSubmitted] = useState(false);

  const optionsAnrede = [
    { label: 'Anrede', value: 'Anrede' },
    { label: 'Frau', value: 'Frau' },
    { label: 'Herr', value: 'Herr' },
    { label: 'divers', value: 'divers' },
    { label: 'keine Angabe', value: 'keine Angabe' },
  ];

  const postData = async (url, body) => {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(body),
    };

    const response = await fetch(url, requestOptions);
    const json = await response.json();

    return json;
  };

  const isMobile = useContext(MobileContext);
  const wrapperRef = useRef(null);
  const onScreen = useIntersectionObserver(wrapperRef);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitSuccessful },
  } = useForm();

  const onSubmit = (formData) => {
    if (data.style === 'secondDefault') {
      formData.standort = valueStandort;
      formData.anrede = valueAnrede;
    }
    const { email: _, ...tempFormData } = formData;
    const body = {
      style: data.style,
      email: formData.email,
      fields: { ...tempFormData },
    };

    postData(data.apiURL, body);
  };

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset({
        name: '',
        email: '',
        message: '',
        anrede: null,
        firma: '',
        nachname: '',
        standort: null,
        vorname: '',
        titel: '',
        anfrage: false,
      });
      setName(null);
      setEmail(null);
      setMsg(null);
      setValueName('');
      setValueEmail('');
      setValueMsg('');
      setAnrede(null);
      setFirma(null);
      setNachname(null);
      setStandort(null);
      setVorname(null);
      setTitel(null);
      setValueAnrede(null);
      setValueFirma('');
      setValueNachname('');
      setValueStandort(null);
      setValueVorname('');
      setValueTitel('');
      setFormSubmitted(true);
    }
  }, [isSubmitSuccessful, reset]);

  const handleVornameChange = (text) => {
    setValueVorname(text);
    if (valueVorname !== '') {
      setVorname(true);
    } else {
      setVorname(false);
    }
  };
  const handleNachnameChange = (text) => {
    setValueNachname(text);
    if (valueNachname !== '') {
      setNachname(true);
    } else {
      setNachname(false);
    }
  };
  const handleFirmaChange = (text) => {
    setValueFirma(text);
    if (valueFirma !== '') {
      setFirma(true);
    } else {
      setFirma(false);
    }
  };
  const handleAnredeChange = (text) => {
    setValueAnrede(text);
    if (valueAnrede !== '') {
      setAnrede(true);
    } else {
      setAnrede(false);
    }
  };
  const handleTitelChange = (text) => {
    setValueTitel(text);
    if (valueTitel !== '') {
      setTitel(true);
    } else {
      setTitel(false);
    }
  };
  const handleStandortChange = (text) => {
    setValueStandort(text);
    reset({
      anfrage: false,
    });
    if (valueStandort !== '') {
      setStandort(true);
    } else {
      setStandort(false);
    }
  };
  const handleNameChange = (text) => {
    setValueName(text);
    if (valueName !== '') {
      setName(true);
    } else {
      setName(false);
    }
  };
  const handleEmailChange = (text) => {
    setValueEmail(text);
    if (valueEmail !== '') {
      setEmail(true);
    } else {
      setEmail(false);
    }
  };
  const handleMessageChange = (text) => {
    setValueMsg(text);
    if (valueMsg !== '') {
      setMsg(true);
    } else {
      setMsg(false);
    }
  };

  const toggle = () => {
    !valueMsg ? setMsg(false) : setMsg(true);
    !valueEmail ? setEmail(false) : setEmail(true);
    !valueName ? setName(false) : setName(true);
  };

  const toggleSecondary = () => {
    !valueVorname ? setVorname(false) : setVorname(true);
    !valueNachname ? setNachname(false) : setNachname(true);
    !valueFirma ? setFirma(false) : setFirma(true);
    !valueTitel ? setTitel(false) : setTitel(true);
    !valueAnrede ? setAnrede(false) : setAnrede(true);
    !valueStandort ? setStandort(false) : setStandort(true);
    !valueEmail ? setEmail(false) : setEmail(true);
    !valueMsg ? setMsg(false) : setMsg(true);
  };

  const findCheckboxes = (region) => {
    const found = data.regions.find((element) => element.name === region);
    if (found) {
      return found.subcategories;
    } else {
      return [];
    }
  };

  const selectStyles = {
    menu: () => ({
      margin: 0,
      boxShadow: '0px 48px 48px 0px #0E0B350A',
      cursor: 'default',
      position: 'absolute',
      background: '#FFFFFF',
    }),
    menuList: () => ({
      paddingTop: 0,
      paddingBottom: 0,
      zIndex: 50,
      position: 'relative',
      overflow: 'unset',
    }),
    option: (provided, state) => ({
      //...provided,
      color: state.isSelected ? '#5eadb7' : '#000000',
      padding: 16,
      background: '#FFFFFF',
      fontFamily: 'DBSans-Regular',
      zIndex: 50,
      position: 'relative',
    }),
    valueContainer: () => ({
      display: 'flex',
      alignItems: 'center',
    }),
    control: () => ({
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      borderRadius: '4px 4px 0 0',
      background: '#282d370d',
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      color: '#5eadb7',
      transition: 'all .3s ease-out',
      transform: state.selectProps.menuIsOpen ? 'rotate(-180deg)' : null,
    }),
    singleValue: () => ({
      margin: '0px',
      padding: '0px',
      color: '#000000',
      fontFamily: 'DBSans-Regular',
      flex: '2 0 100%',
      cursor: 'default',
    }),
  };

  return (
    <div
      className={classnames(
        styles.contactFormWrapper,
        styles[data.style],
        onScreen?.isIntersecting ? styles.visible : ''
      )}
      id={data.anchorId}
      onClick={toggle}
      ref={wrapperRef}
    >
      {data.style === 'default' && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div
            className={classnames(
              styles.formItem,
              !isMobile ? styles.halfWidth : null
            )}
          >
            <input
              type="text"
              name="name"
              {...register('name', { required: true })}
              onChange={(e) => handleNameChange(e.target.value)}
              onClick={toggle}
            />
            <span
              className={classnames(
                styles.namePlaceholder,
                name ? styles.namePlaceholderAct : null
              )}
            >
              Name*
            </span>
            {errors.name && (
              <span className={styles.error}>This field is required</span>
            )}
          </div>
          <div
            className={classnames(
              styles.formItem,
              !isMobile ? styles.halfWidth : null
            )}
          >
            <input
              type="text"
              name="email"
              {...register('email', {
                required: 'This field is required',
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: 'Invalid email address',
                },
              })}
              onChange={(e) => handleEmailChange(e.target.value)}
              onClick={toggle}
            />
            <span
              className={classnames(
                styles.emailPlaceholder,
                email ? styles.emailPlaceholderAct : null
              )}
            >
              E-Mail*
            </span>
            {errors.email && (
              <span className={styles.error}>{errors.email?.message}</span>
            )}
          </div>
          <div className={styles.formItem}>
            <textarea
              name="message"
              {...register('message', { required: true })}
              onChange={(e) => handleMessageChange(e.target.value)}
              onClick={toggle}
            />
            <span
              className={classnames(
                styles.msgPlaceholder,
                msg ? styles.msgPlaceholderAct : null
              )}
            >
              Meine Frage...*
            </span>
            {errors.message && (
              <span className={styles.error}>This field is required</span>
            )}
          </div>
          <button
            className={classnames(styles.button, styles.primary)}
            type="submit"
            disabled={formSubmitted}
          >
            {formSubmitted ? 'Nachricht erfolgreich versendet' : data.ctaText}
          </button>
          <div className={styles.formItem} style={{ textAlign: 'right' }}>
            <span>*Pflichtfeld</span>
          </div>
        </form>
      )}
      {data.style === 'secondDefault' && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div
            className={classnames(
              styles.formItem,
              !isMobile ? styles.halfWidth : null
            )}
          >
            <Select
              aria-label="Standort dropdown"
              options={data.regions.map((item) => ({
                value: item.name,
                label: item.name,
              }))}
              classNamePrefix="select"
              styles={selectStyles}
              isSearchable={false}
              value={
                standort
                  ? { label: valueStandort, value: valueStandort }
                  : { label: data.regions[0].name, value: data.regions[0].name }
              }
              {...register('standort', {})}
              onChange={(e) => handleStandortChange(e.value)}
            />
          </div>
          <div
            className={classnames(
              styles.formItem,
              !isMobile ? styles.halfWidth : null
            )}
          >
            {findCheckboxes(valueStandort).length ? (
              findCheckboxes(valueStandort).map((item, index) => {
                return (
                  <div key={index} className={styles.selectOptions}>
                    <input
                      type="checkbox"
                      name="anfrage"
                      value={item}
                      {...register('anfrage', {})}
                    />
                    {item}
                  </div>
                );
              })
            ) : (
              <div className={styles.checkboxPlaceholder}>Anfrage</div>
            )}
          </div>
          <div
            className={classnames(
              styles.formItem,
              !isMobile ? styles.halfWidth : null
            )}
          >
            <Select
              aria-label="anrede dropdown"
              classNamePrefix="select"
              options={optionsAnrede}
              styles={selectStyles}
              isSearchable={false}
              onChange={(e) => handleAnredeChange(e.value)}
              value={
                anrede
                  ? { label: valueAnrede, value: valueAnrede }
                  : optionsAnrede[0]
              }
              className={styles.selectStyle}
            />
            {errors.anrede && (
              <span className={styles.error}>This field is required</span>
            )}
          </div>
          <div
            className={classnames(
              styles.formItem,
              !isMobile ? styles.halfWidth : null
            )}
          >
            <input
              type="text"
              name="titel"
              {...register('titel', {})}
              onChange={(e) => handleTitelChange(e.target.value)}
              onClick={toggleSecondary}
            />
            <span
              className={classnames(
                styles.namePlaceholder,
                titel ? styles.namePlaceholderAct : null
              )}
            >
              Titel
            </span>
          </div>
          <div
            className={classnames(
              styles.formItem,
              !isMobile ? styles.halfWidth : null
            )}
          >
            <input
              type="text"
              name="vorname"
              {...register('vorname', { required: true })}
              onChange={(e) => handleVornameChange(e.target.value)}
              onClick={toggleSecondary}
            />
            <span
              className={classnames(
                styles.namePlaceholder,
                vorname ? styles.namePlaceholderAct : null
              )}
            >
              Vorname*
            </span>
            {errors.vorname && (
              <span className={styles.error}>This field is required</span>
            )}
          </div>
          <div
            className={classnames(
              styles.formItem,
              !isMobile ? styles.halfWidth : null
            )}
          >
            <input
              type="text"
              name="nachname"
              {...register('nachname', { required: true })}
              onChange={(e) => handleNachnameChange(e.target.value)}
              onClick={toggleSecondary}
            />
            <span
              className={classnames(
                styles.namePlaceholder,
                nachname ? styles.namePlaceholderAct : null
              )}
            >
              Nachname*
            </span>
            {errors.nachname && (
              <span className={styles.error}>This field is required</span>
            )}
          </div>
          <div
            className={classnames(
              styles.formItem,
              !isMobile ? styles.halfWidth : null
            )}
          >
            <input
              type="text"
              name="firma"
              {...register('firma', {})}
              onChange={(e) => handleFirmaChange(e.target.value)}
              onClick={toggleSecondary}
            />
            <span
              className={classnames(
                styles.namePlaceholder,
                firma ? styles.namePlaceholderAct : null
              )}
            >
              Firma
            </span>
          </div>
          <div
            className={classnames(
              styles.formItem,
              !isMobile ? styles.halfWidth : null
            )}
          >
            <input
              type="text"
              name="email"
              {...register('email', {
                required: 'This field is required',
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: 'Invalid email address',
                },
              })}
              onChange={(e) => handleEmailChange(e.target.value)}
              onClick={toggle}
            />
            <span
              className={classnames(
                styles.emailPlaceholder,
                email ? styles.emailPlaceholderAct : null
              )}
            >
              E-Mail*
            </span>
            {errors.email && (
              <span className={styles.error}>{errors.email?.message}</span>
            )}
          </div>
          <div className={styles.formItem}>
            <textarea
              name="message"
              {...register('message', { required: true })}
              onChange={(e) => handleMessageChange(e.target.value)}
              onClick={toggle}
            />
            <span
              className={classnames(
                styles.msgPlaceholder,
                msg ? styles.msgPlaceholderAct : null
              )}
            >
              Meine Frage...*
            </span>
            {errors.message && (
              <span className={styles.error}>This field is required</span>
            )}
          </div>
          <button
            className={classnames(styles.button, styles.primary)}
            type="submit"
            disabled={formSubmitted}
          >
            {formSubmitted ? 'Nachricht erfolgreich versendet' : data.ctaText}
          </button>
          <div className={styles.formItem} style={{ textAlign: 'right' }}>
            <span>*Pflichtfeld</span>
          </div>
        </form>
      )}
    </div>
  );
}
