import { useEffect, useState } from 'react';

const useIntersectionObserver = (elementRef) => {
  const observerParams = {
    threshold: 0.05,
    root: null,
    rootMargin: '0px',
  };
  const [entry, setEntry] = useState();

  const updateEntry = ([entry]) => {
    if (entry.isIntersecting) setEntry(entry);
  };

  useEffect(() => {
    const node = elementRef?.current;
    const hasIOSupport = !!window.IntersectionObserver;
    if (!hasIOSupport || !node) return;

    const observer = new IntersectionObserver(updateEntry, observerParams);

    observer.observe(node);

    return () => observer.disconnect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [elementRef]);

  return entry;
};

export default useIntersectionObserver;
