export const data = {
  style: 'secondDefault',
  links: [
    {
      external: false,
      anchorId: 'Impressum',
      text: 'Impressum',
    },
    {
      external: true,
      url: 'https://www.google.com',
      text: 'Datenschutz',
      newTab: false,
    },
    {
      external: true,
      url: 'https://www.google.com',
      text: 'DB Training Website',
      newTab: true,
    },
  ],
};
