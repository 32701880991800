import React, { useContext, useState } from 'react';
import styles from '../../styles/components/Header.module.scss';
import classnames from 'classnames';
import { MobileContext } from '../../context/mobileContext';
import closeBurger from '../../assets/images/close-burger.svg';
import contactIconWhite from '../../assets/images/contact-icon-white.svg';

export default function Header({ data }) {
  const isMobile = useContext(MobileContext);
  const [toggle, setToggle] = useState(false);
  return (
    <div className={classnames(styles.headerWrapper, styles[data?.style])}>
      <div className={styles.headerContainer}>
        <div className={styles.logo}>
          <a href="#page-top">
            <img src={data?.logo} alt="logo" />
          </a>
        </div>
        <div className={styles.menuWrapper}>
          <div
            className={classnames(
              styles.burgerMenu,
              toggle && styles.burgerOpen
            )}
            onClick={() => setToggle(!toggle)}
          >
            <span></span>
            <span></span>
            <span></span>
          </div>
          <div className={classnames(styles.menu, toggle && styles.openMenu)}>
            <div
              className={classnames(
                styles.burger_open,
                toggle && styles.showClose
              )}
              onClick={() => setToggle(false)}
            >
              <img src={closeBurger} alt="close-burger" />
            </div>
            <ul>
              {data?.links.map((item, index) => {
                let menuItem;
                if (item.external) {
                  menuItem = (
                    <a
                      href={item.url}
                      target={item.newTab ? '_blank' : '_self'}
                      rel="noreferrer"
                    >
                      {item.text}
                    </a>
                  );
                } else {
                  menuItem = <a href={`#${item.anchorId}`}>{item.text}</a>;
                }
                return (
                  <li key={index} onClick={() => setToggle(false)}>
                    {menuItem}
                  </li>
                );
              })}
              {toggle && (
                <li
                  className={styles.contactUs}
                  onClick={() => setToggle(!toggle)}
                >
                  <a href={`#${data?.contact.anchorId}`}>
                    <div className={styles.contactIcon}>
                      <img src={contactIconWhite} alt="logo" />
                    </div>
                    <span>{data?.contact.text}</span>
                  </a>
                </li>
              )}
            </ul>
          </div>
        </div>
        <div className={styles.contact}>
          <a href={`#${data?.contact.anchorId}`}>
            <div className={styles.contactIcon}>
              <img src={data?.contact.icon} alt="logo" />
            </div>
            {!isMobile && <span>{data?.contact.text}</span>}
          </a>
        </div>
      </div>
    </div>
  );
}
